.kniga-dole {
  z-index: -1;
  transition: opacity 0.3s linear;
}

.wrapper {
  height: 100%;
  min-height: 97.9vh;
  position: relative;
}

.title-wrapper,
.title-wrapper-2 {
  position: relative;
  margin-top: 3%;
  height: 80px;
}
.title-wrapper-2 .title {
  font-size: 20px;
  line-height: 20px;
}
.title {
  position: absolute;
  left: 0; right: 0;
  opacity: 1;
  transition: opacity 0.3s linear;
  margin: 0;
}
.title-result {
  position: absolute;
  left: 0; right: 0;
  opacity: 0;
  transition: opacity 0.3s 1s linear;
  margin: 0;
}

.cards-wrapper {
  position: relative;
  height: 90%;
  min-height: 830px;
}
.card {
  /* position: relative; */
  cursor: pointer;
  position: absolute;
  width: 121px;
  height: 200px;
  border-radius: 20px;
  perspective: 1000px;
  transition: all 0.3s ease-in-out 0s;
}

.card .card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
}
.card .card-flip-front,
.card .card-flip-back {
  position: absolute;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.card .card-flip-back {
  transform: rotateY(180deg);
}
.card .card-flip-back.backward {
  transform: rotateY(180deg) scaleY(-1);
}

.card .card-title {
  color: #FFD497;
  font-family: 'Quicksand', sans-serif;
  opacity: 0;
  transition: opacity 0.3s 1s linear;
  text-align: center;
}

.card:nth-child( 1 ) { z-index: 12 ; }
.card:nth-child( 2 ) { z-index: 11 ; }
.card:nth-child( 3 ) { z-index: 10 ; }
.card:nth-child( 4 ) { z-index: 9 ; }
.card:nth-child( 5 ) { z-index: 8 ; }
.card:nth-child( 6 ) { z-index: 7 ; }
.card:nth-child( 7 ) { z-index: 6 ; }
.card:nth-child( 8 ) { z-index: 5 ; }
.card:nth-child( 9 ) { z-index: 4 ; }
.card:nth-child( 10 ) { z-index: 3 ; }
.card:nth-child( 11 ) { z-index: 2 ; }
.card:nth-child( 12 ) { z-index: 1 ; }

.card:hover {
  animation-name: go-up;
  animation-duration: 0.1s;
  animation-fill-mode: both;
}

@keyframes go-up {
  from {transform: translate(0, 0);}
  to {transform: translate(0, -20px);}
}

.card-selected:hover {
  animation: none;
}

.card-placeholder {
  border-radius: 20px;
  z-index: 0;
  position: absolute;
  width: 121px;
  height: 200px;
}

.cards-reveal .card {
  pointer-events: none;
}
.cards-reveal .card-selected {
  animation-name: feel-the-power;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes feel-the-power {
  0% {transform: scale(1) translate(0, 0); box-shadow: none;}
  50% {transform: scale(1.02) translate(0, -2px); box-shadow: 0px 0px 20px 1px rgba(255, 255, 255, 0.28)}
  100% {transform: scale(1) translate(0, 0px); box-shadow: none;}
}

.cards-revealed .card:not(.card-selected) {
  animation-name: make-room;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
.no-anim .cards-revealed .card:not(.card-selected) {
  animation-duration: 0s;
}

@keyframes make-room {
  to {
    transform: translate(0, -50px);
    opacity: 0;
  }
}

.cards-revealed .card-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.cards-revealed .card.card-selected {
  animation-duration: 0.5s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  animation-iteration-count: initial;
}
.cards-revealed .card.card-selected .card-inner {
  animation-name: flip;
  animation-duration: 0.3s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: initial;
}
.cards-revealed .card.card-selected-1 {
  animation-name: fill-room-1;
}
.cards-revealed .card.card-selected-2 {
  animation-name: fill-room-2;
}
.cards-revealed .card.card-selected-3 {
  animation-name: fill-room-3;
}
.no-anim .cards-revealed .card.card-selected {
  animation-duration: 0s;
  animation-delay: 0s;
}
.no-anim .cards-revealed .card.card-selected .card-inner {
  animation-delay: 0.2s;
}

@keyframes fill-room-1 {
  to {
    transform: scale(1.2);
    top: 5%;
    left: 30%;
  }
}
@keyframes fill-room-2 {
  to {
    transform: scale(1.2);
    top: 5%;
    left: 44%;
  }
}
@keyframes fill-room-3 {
  to {
    transform: scale(1.2);
    top: 5%;
    left: 58%;
  }
}

@keyframes flip {
  to {
    transform: rotateY(180deg);
  }
}

.cards-revealed .card.card-selected .card-title {
  opacity: 1;
}


.analiza-wrapper {
  z-index: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s 1s linear;
}
.analiza-title {
  margin-bottom: -20px;
  font-size: 18px;
}
.rezultat-tekst {
  clip-path: inset(0 100% 0 0);
  opacity: 0;
  transition: clip-path 0.5s 1.3s linear, opacity 1s 1.3s linear;
}

.root-cards-revealed .kniga-dole {
  opacity: 0;
}

.root-cards-revealed .analiza-wrapper {
  opacity: 1;
}
.root-cards-revealed .rezultat-tekst {
  opacity: 1;
  clip-path: inset(0);
}

.button-kod {
  position: relative;
}

.rezultat-tekst a {
  animation: waggle 2.5s 1.5s forwards infinite ease-in-out;
}

@keyframes waggle {
  0% {
    transform: none;
  }
  1% {
    transform: rotateZ(5deg) scale(1.05);
  }
  2.5% {
    transform: rotateZ(-5deg) scale(1.05);
  }
  5% {
    transform: rotateZ(5deg) scale(1.05);
  }
  7.5% {
    transform: rotateZ(-6deg) scale(1.05);
  }
  10% {
    transform: rotateZ(-3deg) scale(1.05);
  }
  12.5% {
    transform: rotateZ(0) scale(1);
  }
  100% {
    transform: none;
  }
}

.button-kod::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 105px;
  right: 105px;
  bottom: 5px;
  z-index: -1;
  animation-name: glow;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  border-radius: 50%;
}

@keyframes glow {
  0% {
    box-shadow: 0 0px 20px 5px white;
  }
  50% {
    box-shadow: 0 0px 40px 15px white;
  }
  100% {
    box-shadow: 0 0px 20px 5px white;
  }
}


.note {
  width: 90%;
  margin-left: 5%;
  background: #0101132e;
  padding: 16px 58px 30px 58px;
  border-radius: 12px;
  border: 0.5px solid #01031370;
}
.note p {
  color:#e3e3e3;
  font-size: 12px;
  line-height: 11px;
}

@media (max-width:1023px) {
  .title {
    font-size: 17px;
    line-height: 17px !important;
    padding: 0 16px !important;
  }
  .title br {
    display: none;
  }
  .title-wrapper-2 .title br {
    display: initial;
  }
  .title-wrapper-2 .title {
    font-size: 14px;
  }
  .title-wrapper-2 .title span {
    font-size: 16px !important;
    line-height: 25px !important;
  }
  .container {
    width: auto !important;
  }
  .izaberite-karte {
    padding:0;
  }
  .wrapper {
    padding: 0;
  }
  .izaberite-karte h2 {
    margin-top: 0;
  }
  .title-wrapper {
    margin-top: 20px;
    height: 70px;
  }
  .kniga-dole img {
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
    width: 96vw;
    max-width: 470px;
    pointer-events: none;
  }
  .cards-wrapper {
    min-height: 660px;
    height: 85.4vh;
  }
  .card-placeholder {
    pointer-events: none;
  }
  .cards-row:nth-child(2) .card-placeholder:nth-child(1) {
    top: 41.5% !important;
    left: calc(100vw / 2 - (121px + (121px / 2)))  !important;
  }
  .cards-row:nth-child(2) .card-placeholder:nth-child(2) {
    top: 41.5% !important;
    left: calc(100vw / 2 - (121px / 2))  !important;
  }
  .cards-row:nth-child(2) .card-placeholder:nth-child(3) {
    top: 41.5% !important;
    left: calc(100vw / 2 + (121px / 2))  !important;
  }
  .card:nth-child(1) {left: calc(100vw / 12 * 1 - (3% * 1)) !important}
  .card:nth-child(2) {left: calc(100vw / 12 * 2 - (3% * 2)) !important}
  .card:nth-child(3) {left: calc(100vw / 12 * 3 - (3% * 3)) !important}
  .card:nth-child(4) {left: calc(100vw / 12 * 4 - (3% * 4)) !important}
  .card:nth-child(5) {left: calc(100vw / 12 * 5 - (3% * 5)) !important}
  .card:nth-child(6) {left: calc(100vw / 12 * 6 - (3% * 6)) !important}
  .card:nth-child(7) {left: calc(100vw / 12 * 7 - (3% * 7)) !important}
  .card:nth-child(8) {left: calc(100vw / 12 * 8 - (3% * 8)) !important}
  .card:nth-child(9) {left: calc(100vw / 12 * 9 - (3% * 9)) !important}
  .card:nth-child(10) {left: calc(100vw / 12 * 10 - (3% * 10)) !important}
  .card:nth-child(11) {left: calc(100vw / 12 * 11 - (3% * 11)) !important}
  .card:nth-child(12) {left: calc(100vw / 12 * 12 - (3% * 12)) !important}


  .cards-wrapper.cards-revealed {
    height: 660px;
  }
  .cards-revealed .card.card-selected-1 {
    animation-name: fill-room-1-mobile;
  }
  .cards-revealed .card.card-selected-2 {
    animation-name: fill-room-2-mobile;
  }
  .cards-revealed .card.card-selected-3 {
    animation-name: fill-room-3-mobile;
  }

  .root-cards-revealed .analiza-wrapper {
    margin-top: -560px;
    padding: 0;
  }

  .analiza-wrapper {
    padding: 0 16px;
    margin-top: -600px;
    position: initial;
    bottom: 0;
    pointer-events: none;
  }
  .analiza-wrapper .analiza > div {
    padding: 0 !important;
  }
  .analiza-title {
    margin-top: 8px;
    margin-bottom: 0px;
    padding: 0 16px;
    font-size: 4vw;
    color: white;
  }
  .rezultat-tekst {
    background: none;
    padding-top: 0;
    padding-bottom: 35px;
    height: auto;
  }
  .rezultat-tekst::before {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    content: "";
    z-index: -1;
    height: 65px;
    background: url(/img/text-bkg-bottom.png) no-repeat top center scroll;
    background-size: cover;
  }
  .rezultat-tekst .left {
    padding-left: 15px;
    background: url(/img/mobile-txt-bkg.png) no-repeat top center scroll;
    padding-top: 40px;
    background-size: cover;
  }
  .rezultat-tekst p {
    font-size: 14px;
  }
  .resultat-tekst p.promene {
    font-size: 16px;
  }
  .analiza-wrapper .right a {
    animation: waggle 2.5s 1.5s forwards infinite ease-in-out;
  }
  .button-kod {
    margin-top: 20px;
    margin-bottom: 20px;
    background: url(/img/button.png) no-repeat center center scroll;
  }
  .button-kod h3 {
    padding-top: 14px;
    margin-bottom: 0;
  }
  .button-kod h4 {
    margin-top: 0;
  }
  .root-cards-revealed .kniga-dole {
    opacity: 1;
  }
  .root-cards-revealed .kniga-dole img {
    bottom: 508px;
    width: 130%;
    left: -15%;
    max-width: initial;
  }
  .root-cards-revealed .note {
    background: #10112391;
    /* border: none; */
    /* margin-top: 300px !important; */
  }
}

@keyframes fill-room-1-mobile {
  to {
    transform: scale(0.5);
    top: -10%;
    left: 10%;
  }
}
@keyframes fill-room-2-mobile {
  to {
    transform: scale(0.5);
    top: -10%;
    left: 35%;
  }
}
@keyframes fill-room-3-mobile {
  to {
    transform: scale(0.5);
    top: -10%;
    left: 60%;
  }
}